.App {
  /* text-align: center; */
  font-family: "Source Sans Pro", sans-serif;
  background-color: #f7f2e9;
}
.button {
  padding: 5px 20px;
  margin: 8px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 300;
  border-radius: 5px !important;
}

.btn {
  background-color: #ac8435 !important;
  border-radius: 4px;
}
.btn-1 {
  color: #ffffff !important;
  background-color: #ac8435;
  border: 1px solid #ac8435;
}
.btn-2 {
  color: #403c34 !important;
  background-color: transparent !important;
  border: 1px solid #403c34 !important;
}
.btn-2:hover {
  color: #ffffff !important;
  background-color: #ac8435 !important;
  border: 1px solid #ac8435 !important;
}
.active {
  color: #ac8435 !important;
}
.warning {
  font-size: 14px;
  color: red !important;
}
.swiper {
  width: 60vw;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */

  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.swiper-pagination {
  position: relative !important;
  padding-top: 10px;
}
.swiper-pagination-bullet-active {
  background-color: #ac8435 !important;
}
.important {
  color: #ac8435 !important;
  text-decoration: none !important;
}
@media only screen and (max-width: 1024px) {
  .swiper {
    width: 100% !important;
  }
}
