.container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  text-align: center;
}
.heading {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 56px;
  color: #2b292b;
}
.inputGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.inputGroup input {
  width: 35rem;
  height: 100%;
  padding: 16px 32px;
  border-radius: 4px;
}
.checkbox {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #403c34;
  display: flex;
  justify-content: center;
  gap: 10px;
}
/* .checkbox input:checked {
  border-color: red !important;
  background-color: red !important;
} */
.policy {
  color: #ac8435;
  margin: 0 5px;
}
.policy:hover {
  color: #ac8435;
}
@media screen and (max-width: 480px) {
  .heading {
    font-size: 32px;
  }
}
