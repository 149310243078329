.cart {
  padding: 5rem 0;
  overflow-x: hidden;
}

.heading {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2b292b;
}
.table {
  width: 100%;
}
.table thead {
  border-bottom: 1px solid #e0e0e0;
}
.table thead th {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #403c34;
  padding: 10px;
}
.table tr {
  border-bottom: 1px solid #e0e0e0;
}
.table td {
  padding: 10px !important;
}

.table img {
  height: 48px;
  width: 48px;
  border-radius: 8px;
}
.name {
  font-family: "Libre Baskerville", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 4px;
  color: #2b292b;
}
.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  margin: 0;
}
.price {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  color: #403c34;
}
.quantity {
  border-bottom: 1px solid #2b292b;
  border-top: 1px solid #2b292b;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.quantity input {
  background: transparent !important;
  border: none;
  margin-left: 10px;
}
.quantity input:focus {
  box-shadow: none;
}
.recite {
  border-left: 1px solid #e0e0e0;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.info {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: right;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.message {
  border: 1px solid #e0e0e0;
  width: 100%;
}
.button {
  background: none !important;
  border: none !important;
  padding: 5px 10px;
}
