.quotes {
  background-color: #ffffff;
  background-image: url("../../Assets/Image/quotes.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 12px;
  position: relative;
  z-index: 4;
}
.quote {
  height: 40px;
}
.quoteEnd {
  display: inline;
  transform: rotate(180deg);
}
.slideText {
  display: flex;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 45px;
  font-weight: 600;
  color: #2b292b;
}

.icon img {
  height: 85px;
  width: auto;
}
@media only screen and (max-width: 480px) {
  .quote {
    height: 25px;
  }
  .slideText {
    font-size: 18px !important;
  }
}
