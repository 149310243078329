.loginSec {
  padding: 5rem 4rem !important;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 38px;
  font-weight: 600;
  color: #2b292b;
}
.form {
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #2b292b;
}
.form input,
textarea,
select {
  border-radius: 0 !important;
  padding: 12px 24px !important;
  box-shadow: none !important;
}
.form input:focus,
textarea:focus,
select:focus {
  border: 1px solid #403c34 !important;
}
.formGroup {
  margin: 10px 0 !important;
}
input[type="checkbox"] {
  padding: 0 !important;
  border: 2px solid #ac8435;
}
input[type="checkbox"]:checked {
  padding: 0 !important;
  border: 2px solid #ac8435;
  background-color: #ac8435;
}
.checkbox {
  padding-left: 10px;
  margin-top: 4px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0 !important;
}
.sideText {
  font-size: 18px;
  text-decoration: none !important;
  color: black !important;
}
@media only screen and (max-width: 500px) {
  .title {
    font-size: 30px;
  }
}
