.card {
  position: relative;
  max-width: 250px;
  height: auto;
  margin: 12px 5px;
  padding: 20px 20px 0 20px;
  border-radius: 8px;
  background-image: url("../../../Assets//Image/itemBg.png");
  background-color: white;
}
.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 24px;
}
.rating {
  margin: 10px auto;
}
.rating img {
  width: 24px;
  height: auto;
  margin: auto 3px;
}
.name {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #2b292b;
}
.price {
  background-color: #ac8435;
  color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 5px 20px;
  text-decoration: none;
  height: fit-content;
  width: 100px;
}
.price a:hover {
  color: #ffffff !important;
}
.ingrident {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.offer {
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  margin-bottom: 50px;
}
.button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-radius: 0px 0px 8px 8px !important;
}
