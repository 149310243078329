.contact {
  padding: 5rem 15px;
  overflow-x: hidden !important;
}
.card {
  background-color: white;
  border-radius: 16px;
  padding: 0 !important;
  filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.16));
}
.card img {
  border-radius: 16px 0 0 16px;
}
.contactSec {
  padding: 3rem 7%;

  width: 100%;
  height: 100%;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #2b292b;
}
.form {
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #2b292b;
}
.form input,
textarea,
select {
  border-radius: 0 !important;
  padding: 12px 24px !important;
  box-shadow: none !important;
}
.form input:focus,
textarea:focus,
select:focus {
  border: 1px solid #403c34 !important;
}
.formGroup {
  margin: 10px 0 !important;
}
input[type="checkbox"] {
  padding: 0 !important;
  border: 2px solid #ac8435;
}
input[type="checkbox"]:checked {
  padding: 0 !important;
  border: 2px solid #ac8435;
  background-color: #ac8435;
}
.checkbox {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0 !important;
}
.contactInfo {
  margin: 15px 0;
}

@media only screen and (max-width: 500px) {
  .title {
    font-size: 30px;
  }
}
