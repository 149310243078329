body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input,
textarea,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: none;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6); */
  outline: 0 none;
}
input[type="checkbox"],
input[type="racdio"] {
  padding: 0 !important;
  border: 2px solid #ac8435;
}
input[type="radio"] {
  border-radius: 4px !important;
  border: 1px solid #ac8435;
  outline: 0 none !important;
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  padding: 0 !important;
  border: 2px solid #ac8435;
  background-color: #ac8435;
  border-radius: 4px;
  /* background-image: none !important; */
}
input[type="radio"]:active {
  border: none !important;
  outline: 0 none !important;
}
.checkbox {
  padding-left: 10px;
  margin-top: 4px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0 !important;
}
/* input[type="checkbox"]:checked {
  border-color: red !important;
  background-color: red !important;
} */
button:focus {
  /* border: none !important; */
  /* outline: 0 !important; */
  box-shadow: none !important;
}
