.category {
  overflow-x: hidden;
  padding: 5rem 0;
}
.heading {
  text-align: center;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 45px;
  font-weight: 600;
  /* margin: 5% 0; */
  color: #2b292b;
}
.content {
  padding-top: 5rem;
}
.title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin: 15px 0;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media screen and (max-width: 480px) {
  .heading {
    font-size: 30px;
  }
}
