@font-face {
  font-family: "Small-Kitchen";
  src: url("../../../Assets/Fonts/Small\ Kitchen.ttf");
}
.card {
  display: inline-block;
  text-align: left;
  position: relative;
  margin: 5px;
  max-width: 350px;
  height: auto;
  border-radius: 8px;
  padding: 20px;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000083;
  border-radius: 8px;
}
.heading {
  font-size: 34px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  z-index: 2;
  position: relative;
  text-shadow: 2px 2px 4px #00000062;
}
.title {
  position: relative;
  font-family: "Small-Kitchen", sans-serif;
  font-size: 40px;
  padding: 15px 0;
  color: #ffffff;
  text-shadow: 2px 2px 4px #00000062;
}
.text {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  max-width: 250px;
  color: #ffffff;
  text-shadow: 2px 2px 4px #00000062;
}
.card:hover {
  box-shadow: 5px 5px 10px #00000062;
  transform: scale(1.01);
  transition: 0.2s;
}
