.card {
  margin-top: 10rem;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  position: relative;
  width: 345px;
  height: 350px;
  background-image: url("../../../Assets/Image/itemBg.png");
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 2rem;
}

.content p {
  font-family: "Libre Baskerville", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  text-transform: capitalize;
  padding-top: 25px;
}
.content img {
  margin: auto;
  height: 350px;
  width: fit-content;
}
.card:hover {
  /* border: 2px solid rgba(255, 255, 255, 0.281); */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: 0.6s;
}
