.card {
  margin: 30px 12px;
  max-width: 320px;
  border-radius: 12px;
  position: relative;
}
.img {
  width: 100%;
  margin-bottom: 64px;
  border-radius: 8px;
}
.card h5 {
  margin-bottom: 24px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  font-size: 20px;
  color: #2b292b;
}
.card p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;

  color: #403c34;
}
.routine {
  border: 4px solid #ac8435;
}
.info {
  position: absolute;
  top: 0;
  padding: 40px 25px;
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(34, 34, 34, 0.86),
    rgba(34, 34, 34, 0.86)
  );
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 50px;
}

table hr {
  border-top: 2px solid #ffffff !important;
  padding: 0 !important;
  width: 50px;
  margin: 0 10px;
}
table tbody {
  border: none !important;
}
table .day {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 15px 0 !important;
}
table .time {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #aaaaaa;
}
table .closed {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ac8435;
  text-decoration: none !important;
}
.call {
  position: absolute;
  bottom: 70px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ac8435;
  text-decoration: none !important;
}
.call:hover {
  color: #ac8435 !important;
}
.btn {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 20px;
  margin: 0;
}
footer {
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .card {
    width: 345px;
  }
  table hr {
    width: 25px !important;
  }
}
@media screen and (max-width: 480px) {
  .info {
    padding: 20px 20px;
  }
  .title {
    margin-bottom: 15px;
  }
  .call {
    bottom: 35px;
  }
}
