.food {
  overflow-x: hidden !important;
}
.bannerTop {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url("../../Assets/Image/foodBanner.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50% 100%;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #2b292b;
}
.desc {
  max-width: 550px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
}
.sortSec {
  padding: 45px 0;
  border-bottom: 1px solid #e0e0e0;
}
.formSelect {
  background-color: white !important;
  border: none !important;
  appearance: initial;
  border-radius: 4px !important;
}
.filterSec {
  padding: 40px 10px 20px 5px;
  border-top: 1px solid #e0e0e0;
}
.filterBlock {
}
.filterBlock .title {
  font-size: 22px;
}
.cards {
}
@media only screen and (max-width: 800px) {
  .bannerTop {
    background-image: none !important;
  }
}
@media only screen and (max-width: 500px) {
  .title {
    font-size: 30px;
  }
}
