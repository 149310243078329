.popularItem {
  padding: 5rem;
}
.heading {
  text-align: center;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 45px;
  font-weight: 600;
  /* margin: 5% 0; */
  color: #2b292b;
}
.content {
  text-align: center;
}
.title {
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-weight: 400;
  max-width: 650px !important;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 5rem; */
  padding-top: 22px;
}
@media screen and (max-width: 480px) {
  .heading {
    font-size: 30px;
  }
}
