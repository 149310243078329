.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px); /* Apply the blur effect */
  z-index: 1050;
}
.modal {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 550px;
  margin: 10px;
}
.header {
  padding: 20px 40px;
  background-color: #ac8435;
  color: #ffffff;
  font-family: "Libre Baskerville", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 10px;
}
.header h3 {
  margin: 0;
}
.body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Libre Baskerville", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
}
/* .body p {
  text-align: center;
} */
.footer {
  padding: 20px;
}
.btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none !important;
  border: none !important;
}
