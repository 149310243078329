.headerTop {
  background-color: #403c34;
  color: #ffffff;
  padding: 10px 0;
}
.headerItems {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}
.headerText {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 10px !important;
  font-weight: 400;
  padding: 0 15px;
  align-items: center;
}
.headerInfo {
  display: flex;
}
.headerIcon {
  padding-right: 15px;
}
