.sideBar {
  display: flex;
  align-items: center;
  height: 95%;
}
.container {
  padding-left: 10vw;
}
.navItem {
  display: block;
  text-decoration: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 5px 15px !important;
  margin-bottom: 15px;
  color: #000000;
}
