.about {
  overflow-x: hidden !important;
}
.section {
  padding: 80px 0;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #2b292b;
}
.subTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #2b292b;
}
.desc {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #403c34;
  padding: 30px 0;
  margin: 0;
}
.infoSection {
  margin: auto;
  text-align: center;
}
.collapseSec {
  padding: 40px 0;
}
.accord {
  background-color: #f7f2e9 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
  margin: 10px 0 !important;
}
.accordHeader {
  font-family: "Libre Baskerville" !important;
  font-weight: 400;
  font-size: 42px !important;
  color: #2b292b !important;
}
.accordHeader button {
  background: #f7f2e9 !important;
  font-family: "Libre Baskerville" !important;
  font-weight: 400;
  font-size: 18px !important;
  color: #2b292b !important;
}
.form {
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #2b292b;
}
.infoSection .form input,
textarea,
select {
  background: transparent !important;
  border-radius: 0 !important;
  padding: 12px 24px !important;
  box-shadow: none !important;
}
.form input:focus,
textarea:focus,
select:focus {
  border: 1px solid #403c34 !important;
}
.formGroup {
  margin: 10px 0 !important;
}
