.card {
  background-image: url("../../../Assets/Image/itemBg.png");
  background-color: #ffffff;
  position: relative;
  border-radius: 8px;
  height: auto;
  padding: 25px;
  margin: 8rem 12px 12px 12px;
}
.img {
  position: absolute;
  height: 234px;
  max-width: 220px;
  padding: 35px;
  top: -135px;
  right: 0;
  border-radius: 40px;
}
.price {
  background-color: #ac8435;
  color: #ffffff;
  border: 1px solid #000 !important;
  border-radius: 5px;
  padding: 5px 20px;
  text-decoration: none;
}
.button {
  background-color: #ac8435;
  color: #ffffff;
  border: none !important;
  border-radius: 5px;
  padding: 5px 20px;
  text-decoration: none;
}
.price a:hover {
  color: #ffffff !important;
}
.rating {
  margin: 10px auto;
}
.rating img {
  margin: auto 3px;
}
.name {
  margin: 15% auto;
  text-align: center;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #2b292b;
}
.description {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ingrident {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.discount {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin: 10px;
}
.discount span {
  color: #ac8435;
}

.card:hover .img {
  /* padding: 10px; */
  transform: scale(1.05);
  transition: 0.6s;
  top: -120px;
}
@media screen and (max-width: 480px) {
  .img {
    padding: 35px;
  }
  .rating img {
    max-width: 20px;
  }
}
@media screen and (max-width: 1280px) {
  .price {
    padding: 5px 10px;
  }
}
