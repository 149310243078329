.reservation {
  /* padding: 5rem 4rem !important; */
  overflow-x: hidden !important;
}
.bannerTop {
  padding-top: 5rem;
  padding-bottom: 5rem;
  /* background-image: url("../../Assets/Image/reservation.png"); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50% 100%;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 38px;
  font-weight: 600;
  color: #2b292b;
  margin: 0px;
}
.desc {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #403c34;
  padding: 30px 0px;
}
.radio {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ac8435;
}
.timeSelection {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.timeSelection input,
select {
  width: auto;
  background-color: #ffffff;
  border-radius: 0px !important;
  /* max-width: 150px; */
}
.selection {
  max-width: 170px;
}
.timeSelection input[type="date"],
input[type="time"] {
  background: none !important;
  width: 50%;
}
.btn {
  margin-left: 10px;
  padding: 8px 20px;
}
.subTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #403c34;
}
.table {
  padding: 15px 5px;
  border-bottom: 1px solid #e0e0e0;
}
.table p {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: #403c34;
}
.info {
  padding: 10px 35px;
  margin: 20px 0;
  background: #f8e8cb;
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #403c34;
}
/* copied */
.form {
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #2b292b;
}
.form input,
textarea,
select {
  border-radius: 0 !important;
  padding: 12px 24px !important;
  box-shadow: none !important;
}
.form input:focus,
textarea:focus,
select:focus {
  border: 1px solid #403c34 !important;
}
.formGroup {
  margin: 10px 0 !important;
}
input[type="checkbox"] {
  padding: 0 !important;
  border: 2px solid #ac8435;
}
input[type="checkbox"]:checked {
  padding: 0 !important;
  border: 2px solid #ac8435;
  background-color: #ac8435;
}
.checkbox {
  padding-left: 10px;
  margin-top: 4px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0 !important;
}
.infoSec {
  padding: 20px;
}
.details {
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}
.detailsLine {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #403c34;
  margin: 10px 0;
  gap: 10px;
  display: flex;
  align-items: center;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #403c34;
}
.checkbox {
  display: flex;
  gap: 10px;
}
.checkbox p {
  font-size: 14px;
}
.footer {
  font-size: 12px;
}
@media only screen and (width: 500px) {
  .selection {
    width: 100%;
  }
}
