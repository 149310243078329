.review {
  background-color: #ffffff;
  background-image: url("../../Assets/Image/quotes.png");
  padding: 3%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.container {
}
.quote {
  height: 40px !important;
}
.quoteEnd {
  display: inline;
  transform: rotate(180deg);
}
.slideText {
  display: flex;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #2b292b;
}
.img {
  height: auto;
  width: 100%;
  align-items: center;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: #2b292b;
  margin-bottom: 40px;
}
.clientInfo {
  text-align: left;
}
.clientInfo .name {
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 8px;
  color: #403c34;
}
.clientInfo img {
  margin-right: 5px;
}
.verified {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #403c34;
}
.social .socialLink {
  margin: 5px 12px;
}
@media screen and (max-width: 480px) {
  .title {
    font-size: 25px;
  }
  .slideText p {
    font-size: 18px;
  }
  .quote {
    height: 20px !important;
  }
}
