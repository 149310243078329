.Banner {
  background-image: url("../../Assets/Image/backGround.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  height: 100%;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.imgContainer {
  display: flex !important;
  align-items: flex-end;
  height: 100%;
  width: auto;
  bottom: 0;
  position: relative;
  z-index: 2;
}
.imgContainer img {
  padding: 0 30px;
  /* height: 90vh; */
  width: auto;
}
.textSection {
  display: flex;
  align-items: center;
  padding: 90px 10px;
}
.titleTop {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #403c34;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 50px;
  font-weight: 600;
  color: #2b292b;
}
.subTitle {
  align-items: center;
  justify-content: left;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #ac8435;
  padding-bottom: 10px;
}
.subTitle span {
  width: fit-content;
}
.separateDot {
  background-color: #ffffff;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  padding: 5px !important;
}
.desc {
  max-width: 450px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  color: #403c34;
}
.btn {
  padding: 10px 20px;
  margin: 10px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  background-color: #ac8435;
  border: none !important;
  border-radius: 5px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media screen and (max-width: 480px) {
  .title {
    font-size: 25px;
  }
}
