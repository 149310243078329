.NavBar {
  padding: 10px 27px !important;
  box-shadow: 0px 2px 10px 0px #00000013;
  background-color: #ffffff;
}
.navSmall {
  padding: 10px;
  background-color: #f7f2e9;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  box-shadow: 0px 4px 2px -2px #00000013;
  z-index: 102;
}
.navSmall img {
  height: 30px;
  width: auto;
}

.navItem {
  text-decoration: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 5px 15px !important;
  color: #000000;
}
.subMenu::after {
  content: "\f107";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding-left: 5px;
  padding-top: 5px;
}
.search {
  padding: 0 15px;
  display: flex;
  align-items: center;
  position: relative;
}
.searchBar {
  position: absolute;
  bottom: -50px;
  right: 10px;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 8px 0px 8px 8px;
  padding: 5px 10px;
}
.searchBar input {
  border: 1px solid #646464;
  border-radius: 8px 0px 8px 8px;
  padding: 5px 10px;
}
.cartItem {
  padding: 15px;
  color: #000000;
}
.price {
  font-size: 14px;
  padding: 2px 8px;
  margin: 5px;
  background: #ac8435;
  color: #ffffff;
  border-radius: 20px;
}

.navItem:hover {
  color: #ac8435 !important;
}
