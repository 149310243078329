.footer {
  background-color: #ffffff;
  background-image: url("../../Assets//Image/footerBg.png");
  background-position: right;
  background-repeat: no-repeat;
  padding-top: 5rem;
  text-align: left !important;
}
.footer a {
  color: #ac8435;
}
.footer a:hover {
  color: #8b6b2b;
}
.logo {
  height: 60px;
  margin-bottom: 20px;
  align-items: start;
}
.desc {
  margin-top: 10px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #403c34;
}
.title {
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: #2b292b;
  margin-bottom: 24px;
}
.navItems {
  list-style: none;
  padding-left: 0;
}
.navItem {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #403c34;
  margin: 4px 0;
}
.navItem a {
  text-decoration: none;
  color: #403c34;
}
.locationContainer {
  display: flex;
  /* flex-wrap: wrap; */
}
.locationContainer img {
  width: 200px;
  height: auto;
}
.payment {
  height: 100%;
}
.payment img {
}
.paymentInfo {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 20px;
}
.paymentInfo svg {
  margin: 5px 12px;
}
.social {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 20px;
}
.social .socialLink {
  margin: 5px 12px;
}
.footerBtm {
  padding: 40px;
  text-align: center;
}
.footerBottom {
  padding: 0 5px;
}
