.contain {
  background-image: url("../../Assets//Image/bean.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 1vw;
}
.discount {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 5rem 0;
}
