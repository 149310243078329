.contain {
  background-image: url("../../Assets//Image/bean2.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 90% 115%;
  overflow-x: hidden !important;
}
.container {
  padding: 5rem 15px;
}
.about {
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.card {
  /* position: relative; */
  height: 20rem;
  width: 25%;
  background-color: aqua;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  transition: 0.6s;
}
.texts {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}
.about .img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #2b292b;
}
.subTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #2b292b;
}
.desc {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #2b292b;
  margin: 30px 0;
  text-align: justify;
  max-width: 650px;
}
.coffee {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  .card {
    height: 10rem;
  }
  .title {
    font-size: 30px;
  }
  .coffee img {
    height: 10rem;
  }
}
